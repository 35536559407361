import { type ReactNode } from 'react';

import { useInstance } from '../hooks/useInstance';
import { ConfirmCancelModalProvider } from './ConfirmCancelModalContext';
import { DeviceContextProvider } from './Device';
import { useCreateDefaultVideoStreamMixer } from './Device/video-stream-mixer';
import {
  FirebaseContextProvider,
  firebaseService,
  useInitFirebase,
} from './Firebase';
import { FirebaseUtils } from './Firebase/utils';
import { GameCoverClip } from './GamePackCover/GamePackCoverPres';
import {
  NotificationContextProvider,
  useNotificationDataSourceReactState,
} from './Notification/Context';
import { ProvidersList } from './ProvidersList';
import { useUser } from './UserContext';
import { VenueContextProvider } from './Venue';
import { useFirebaseVenueSyncing } from './Venue/useFirebaseVenueSyncing';

function Bootstrap() {
  const user = useUser();
  useInitFirebase(!!user.id);
  useFirebaseVenueSyncing(true);

  return null;
}

export function DefaultPageProvider(props: {
  children: ReactNode;
  inZoom?: boolean;
}) {
  const notificationDataSource = useNotificationDataSourceReactState();
  const user = useUser({ init: true });
  const recoveryConfig = useInstance(() => FirebaseUtils.RecoveryConfig());
  const mixer = useCreateDefaultVideoStreamMixer();

  if (!user.id) return null;

  const providers = [
    <VenueContextProvider />,
    <DeviceContextProvider
      profileIndex='720p'
      mixer={mixer}
      noop={props.inZoom}
    />,
    <FirebaseContextProvider
      svc={firebaseService}
      recoveryConfig={recoveryConfig}
    />,
    <ConfirmCancelModalProvider />,
    <NotificationContextProvider datasource={notificationDataSource} />,
  ];

  return (
    <ProvidersList providers={providers}>
      {props.children}
      <Bootstrap />
      <GameCoverClip id='game-cover-clip' />
    </ProvidersList>
  );
}
